import React from "react";
import { Button } from "react-scroll";

const Home = () => {
  return (
    <div id="home" name="home" className="w-full h-screen bg-[#0a192f]">
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-gray-200 py-2 md:text-xl">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ff6666]">
          Matt Neary.
        </h1>
        <h2 className="text-3xl py-2 sm:text-6xl font-bold text-gray-400">
          I am a Front-End Developer.
        </h2>
        <p className="text-gray-300 pb-4 max-w-[700px] md:text-xl">
          I specialize in building enjoyable digital experiences. 
        </p>
        <div>
          <Button
            to="work"
            smooth={true}
            duration={500}
            type="submit"
            value="View My Work"
            className="view-work"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
