import React from "react";
import CSSIcon from "../assets/css.png";
import GitHubIcon from "../assets/github.png";
import HTMLIcon from "../assets/html.png";
import JavaScriptIcon from "../assets/javascript.png";
import ReactIcon from "../assets/react.png";
import TailwindIcon from "../assets/tailwind.png";

const Skills = () => {
  return (
    <div
      id="skills"
      name="skills"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-[#ff6666]">
            Skills
          </p>
          <p className="py-4">
            // These are some of the technologies I have worked with.
          </p>
        </div>
        <div className="w-full grid grid-cols-3 gap-4 text-center py-8">
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={HTMLIcon}
              alt="HTML Icon"
            />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={CSSIcon}
              alt="CSS Icon"
            />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={JavaScriptIcon}
              alt="JavaScript Icon"
            />
            <p className="my-4">JavaScript</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={ReactIcon}
              alt="React Icon"
            />
            <p className="my-4">React</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={GitHubIcon}
              alt="GitHub Icon"
            />
            <p className="my-4">GitHub</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-15 sm:w-20 mx-auto"
              src={TailwindIcon}
              alt="Tailwind Icon"
            />
            <p className="my-4">Tailwind</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
