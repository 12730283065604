import React from "react";

const About = () => {
  return (
    <div
      id="about"
      name="about"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="pb-6 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-[#ff6666]">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-6 px-4">
          <div className="text-3xl font-bold">
            <p>
              Nice to meet you! <br /> Please take a look around.
            </p>
          </div>
          <div>
            My name is Matt, and I am a Front-End Developer, currently studying
            with Nucamp to become a Full-Stack (MERN) Developer. Outside of my
            work and studies, I like going to the gym, music, soccer, and
            gaming.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
